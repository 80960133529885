import { api } from ":mods";
import { ENDPOINTS } from "../const";
import { CreateAccountProps } from "../models";

export async function createAccount(data: CreateAccountProps, type = "student") {
  const url = type === "student" ? ENDPOINTS.post_account_sign_up_student : ENDPOINTS.post_account_sign_up_tutor;
  return api
    .post(url, data)
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
